import { Card, Col, Image, Row, Stack } from "react-bootstrap";
import comingSoonImage from "../../assets/images/coming-soon.svg";
import Loader from "../../../components/common/Loader";
import Documents from "./sections/Documents";
import { useEffect, useState } from "react";
import Questionnaires from "./sections/Questionnaires";
import CaseDetails from "./sections/CaseDetails";
import { dashboardCombineAction } from "../../../redux/companyDashboardModule/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";

const DashboardCompany = () => {
  const isLoading = useSelector((state: any) => state.companyDashboardSlice.loading);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const dashboardData = useSelector((state: any) => state.companyDashboardSlice.dashboardCompanyData);
  
  const getDashboardData = () => {
    const payload = {
      case_id: 200
    }
    dispatch(dashboardCombineAction(payload))
  }

  useEffect(() => {
    getDashboardData();
  }, [])


  return (
    <>
      <Loader isLoading={isLoading} />
      <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto new-theme-updates">
        <Row className="gx-3 py-2">
          {/* Documents Section */}
          <Col sm={6} className="mb-3 pb-1">
            <Documents dashboardData={dashboardData} />
          </Col>

          {/* Questionnaires Section */}
          <Col sm={6} className="mb-3 pb-1">
            <Questionnaires dashboardData={dashboardData} />
          </Col>

          {/* Case Details Section */}
          <Col xs={12} className="mb-3">
            <CaseDetails dashboardData={dashboardData?.allCasesRelatedTocompany} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DashboardCompany;
